import React from "react";
import { Link } from "gatsby-plugin-intl";

export function AutoLink(props) {
  const { children, ...containerProps } = props;
  if (props.href) {
    return <a {...containerProps}>{children}</a>;
  } else {
    return <Link {...containerProps}>{children}</Link>;
  }
}
