import React from "react";

import { concatClassNames as cn } from "@system42/core";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";
import { AutoLink } from "../components/helpers/AutoLink";

const Gdpr = () => (
  <Layout title={"GDPR"}>
    <article>
      <HeaderTitle overline={"Last updated: June 1, 2018"} titleWidth={"13em"}>
        GDPR (General Data Protection Regulation)
      </HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <p>
            As of May 25th 2018, the European Union's (EU) regulation, the
            General Data Protection Regulation (GDPR), has been enforceable and
            we have diligently ensured that Userbrain's practices are compliant
            with GDPR.
          </p>

          <p>
            Userbrain is and remains steadfast in our commitment to safeguarding
            the security and privacy of the data entrusted to us, as well as
            aiding our customers to comply with regulations like the GDPR.
          </p>

          <p>
            These are the measures that Userbrain is committed to as one of your
            data processors:
          </p>

          <ul>
            <li>
              Secure Data Transfer and Storage outside the EU: We have ensured,
              and continue to ensure, that transfers of personal data outside
              the European Economic Area (EEA) are conducted under the necessary
              safeguards. Our customer Data Processing Agreement includes the EU
              Model Clauses, which represent industry standards for data safety.
              This signifies Userbrain's commitment to protect any data
              originating from the EEA in accordance with European data
              protection standards.
            </li>
            <li>All data is encrypted in transit.</li>
            <li>
              Userbrain will promptly inform you of any incidents involving your
              users' personal data.
            </li>
          </ul>

          <h3>Data Processing Agreement</h3>
          <p>
            Should you require a Data Processing Agreement from Userbrain, we
            remain at your service at{" "}
            <a href="mailto:support@userbrain.com">support@userbrain.com</a>.
            Kindly provide the following information: account email, name,
            company name, address, country.
          </p>

          <h3>GDPR Compliant Privacy Notice</h3>
          <p>
            We have updated, and continue to maintain, our{" "}
            <AutoLink to="https://www.userbrain.com/en/privacy/">
              privacy policy
            </AutoLink>{" "}
            to encompass all GDPR-related aspects.
          </p>

          <h3>Right to Access and Portability</h3>
          <p>
            Our users have the capability, which we continuously provide, to
            export all their account data into a machine-readable format. This
            is applicable for both customers and testers on the account settings
            page.
          </p>

          <h3>Right to Erasure</h3>
          <p>
            We have empowered our users with the ability to execute a
            GDPR-compliant deletion. This will completely eradicate all traces
            of your customer or tester details, including your user test videos
            from our system, permanently.
          </p>

          <h3>Right to Rectify</h3>
          <p>
            Our users have the option to amend any account-related data, like
            email addresses, on the account settings page. We have ensured that
            updating your contact information is also reflected within
            third-party systems.
          </p>

          <h3>Data Breach and Data Security</h3>
          <p>
            In order to comply with the GDPR, we have implemented and continue
            to maintain a notification system and emergency processes for
            instances of loss, alteration, unauthorized disclosure of or access
            to any personal data. Should a data breach occur, we will promptly
            inform you of any incidents involving your personal data.
          </p>

          <hr />

          <p>
            Should you have any queries about the GDPR or wish to understand how
            Userbrain can assist you in maintaining compliance, please don't
            hesitate to contact{" "}
            <a href="mailto:support@userbrain.com">support@userbrain.com</a>.
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Gdpr;
